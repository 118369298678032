<template>
  <div class="titless">
    <navs @logins="logins"></navs>
    <div class="others">
      <index v-bind:isLogin="isLogin"></index>
    </div>
    <div>
      <bottoms></bottoms>
    </div>
  </div>
</template>

<script>
import index from "../../components/index.vue";
import navs from "../../components/nav.vue";
import bottoms from "../../components/bottom.vue";
export default {
  data() {
    return {
      name: "tlj",
      isLogin:{}
    };
  },
  mounted() {
    var body = document.querySelector("body");
    $(body).css("cursor", "url(/img/favicon.ico),move");
  },
  components: {
    index,
    navs,
    bottoms,
  },
  methods: {
    logins(data) {
      this.isLogin = data;
      // console.log(data);
    },
  },
};
</script>

<style>
/* body {
  background: #f0f0f0 !important;
} */
.titless {
  display: flex;
  flex-direction: column;
}
.others {
  margin-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  opacity: 1;
}
body {
  margin: 0;
  padding: 0;
  font-family: "montserrat";
  background-image: linear-gradient(
    125deg,
    #2c3e50,
    #27ae60,
    #2980b9,
    #e74c3c,
    #8e44ad
  );
  background-size: 400%;
  animation: bganimation 15s infinite;
}
@keyframes bganimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>