import Vue from 'vue'
import App from './APP.vue'
import rem from '../../assets/js/rem'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

Vue.config.productionTip = false
Vue.prototype.$rem = rem
new Vue({
  render: h => h(App),
}).$mount('#app')

